import { Address } from '@/stores/addressStore';

type OmitOptions = 'phone' | 'firstName' | 'lastName';

export const serializeAddress = (
  address: Pick<
    Address,
    'address1' | 'address2' | 'city' | 'zip' | 'country' | 'phone' | 'firstName' | 'lastName'
  >,
  omit: OmitOptions[] = []
): string => {
  return `${address.address1 || ''} ${address.address2 || ''} ${address.city || ''} ${
    address.zip || ''
  } ${address.country || ''} ${omit.includes('phone') ? '' : address.phone || ''} ${
    omit.includes('firstName') ? '' : address.firstName || ''
  } ${omit.includes('lastName') ? '' : address.lastName || ''}`
    .toLocaleLowerCase()
    .replaceAll(' ', '-');
};

export const convertAddressIntoString = (
  address?: {
    address1: string | null;
    address2: string | null;
    city: string | null;
    zip: string | null;
    country?: string | null;
  } | null
) => {
  if (!address) return '';
  return `
  ${address.address1}, ${address.address2 ? address.address2 + ',' : ''} ${address.city}, ${
    address.zip
  }, ${address.country || 'UK'}`;
};

export const convertToShortAddressString = (address: {
  address1: string | null;
  address2: string | null;
  city: string | null;
  zip: string | null;
}): string => {
  const address1 = address.address1 ? address.address1 + ', ' : '';
  const address2 = address.address2 ? address.address2 + ', ' : '';
  const city = address.city ? address.city + ', ' : '';
  return `${address1}${address2}${city}${address.zip}`;
};

export type PostcodeData = {
  status: number;
  result: {
    postcode: string;
    quality: number;
    eastings: number;
    northings: number;
    country: string;
    nhs_ha: string;
    longitude: number;
    latitude: number;
    european_electoral_region: string;
    primary_care_trust: string;
    region: string;
    lsoa: string;
    msoa: string;
    incode: string;
    outcode: string;
    parliamentary_constituency: string;
    parliamentary_constituency_2024: string;
    admin_district: string;
    parish: string;
    admin_county: string | null;
    date_of_introduction: string;
    admin_ward: string;
    ced: string | null;
    ccg: string;
    nuts: string;
    pfa: string;
    codes: {
      admin_district: string;
      admin_county: string;
      admin_ward: string;
      parish: string;
      parliamentary_constituency: string;
      parliamentary_constituency_2024: string;
      ccg: string;
      ccg_id: string;
      ced: string;
      nuts: string;
      lsoa: string;
      msoa: string;
      lau2: string;
      pfa: string;
    };
  };
};

export const checkIfFullPostcode = (postcode: string) => {
  return /^[A-Z]{1,2}\d[A-Z\d]? ?\d[A-Z]{2}$/.test(postcode);
};
